// Fade Right

.fadeRight {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    opacity: 1;
  }
}

// Fade Left

.fadeLeft-1 {
  animation: fadeInLeft 0.3s ease-in-out;
}
.fadeLeft-2 {
  animation: fadeInLeft 1.4s ease-in-out;
}
.fadeLeft-3 {
  animation: fadeInLeft 2s ease-in-out;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    opacity: 1;
  }
}

// Fade In

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes videoFade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.6;
    background-blend-mode: multiply;
    filter: brightness(20%);
  }
}

.site-heading {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.site-subHeading {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.videoFade {
  opacity: 1;
  animation: videoFade 1s;
  animation-delay: 3.3s;
  animation-fill-mode: forwards;
}

.header-fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.3s;
  animation-fill-mode: forwards;
}

.header-fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
}
.header-fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3.7s;
  animation-fill-mode: forwards;
}

.nav-fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.nav-fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.nav-fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.nav-fade-4 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.9s;
  animation-fill-mode: forwards;
}

.nav-fade-5 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.1s;
  animation-fill-mode: forwards;
}
.nav-fade-6 {
  opacity: 0;
  animation: fadeIn 1.3s;
  animation-delay: 1.3s;
  animation-fill-mode: forwards;
}

.nav-fade-7 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.nav-fade-8 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.7s;
  animation-fill-mode: forwards;
}
.nav-fade-9 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1.9s;
  animation-fill-mode: forwards;
}
.nav-fade-10 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
}
