@font-face {
  font-family: "Rushen";
  src: url("fonts/rushen/rushen-curvy.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Rushen";
  src: url("fonts/rushen/rushen-curvy.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: "hellixTrial";
//   src: url("fonts/Hellix/HellixTRIAL-Regular.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "hellixTrial";
//   src: url("fonts/Hellix/HellixTRIAL-Regular.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

html,
body {
  font-family: "Gordita", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

// main {
//   transform: translateZ(0);
// }

.main-wrapper {
  max-width: 1500px !important;
  margin: auto !important;
  position: relative !important;
}

.wrapper {
  position: relative !important;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 1200px) {
    padding-left: 70px;
    padding-right: 70px;
  }
  @media (max-width: 800px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  width: 100%;
  margin: auto !important;
  max-width: 1600px !important;
}

@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.h1,
text {
  font-size: responsive 40px 135px;
  @include letter-spacing(-0.3);
  font-weight: 300;
  font-family: "rushen";
  line-height: responsive 45px 112px;
}
.rushden {
  font-family: "rushen";
}
h2,
.h2 {
  font-size: responsive 30px 70px;
  line-height: responsive 25px 75px;
  font-family: rushen;
}

h3,
.h3 {
  font-size: 30px;
  font-family: rushen;
}

h4,
.h4 {
  font-size: 15px;
  font-family: "HellixTRIAL-Regular";
  letter-spacing: 5px;
  font-size: responsive 20px 30px;
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

a {
  font-family: "rushen";
  font-size: 20px;
  line-height: 30px;
}

p,
.p {
  font-size: responsive 14px 18px;
}

#clip {
  background: url("/web/patterns/blue-pattern.png") no-repeat center center;
  background-size: 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

section {
  position: relative !important;
}

.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    opacity: 0.7;
  }
}

.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .toggle-btn {
    button {
      height: 50px !important;
    }
  }
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  .nav-date {
    display: none !important;
  }
  .donate-btn {
    height: 60px !important;
    -webkit-appearance: none;
    span {
      display: none !important;
    }
  }
  .logo {
    height: 60px !important;
  }
  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-top: 10px !important;
  background-color: rgba(0, 0, 0, 0.753) !important;
  border-bottom: 2px solid #db0366;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-bottom: 10px;

  @media (max-width: 1024px) {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-top: 5px !important;
  }
}

.headroom--top {
  .toggle-btn {
    button {
      height: 60px !important;
    }
  }
  border-bottom: none;
  padding-top: 30px !important;
  .nav-date {
    display: contents !important;
  }
  .donate-btn {
    height: 85px !important;
    -webkit-appearance: none;
    span {
      display: contents !important;
    }
  }
  .logo {
    height: 180px !important;
  }
  @media (max-width: 1024px) {
    .logo {
      height: 150px !important;
    }
    padding-right: 20px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
  }
  @media (max-width: 900px) {
    .logo {
      height: 90px !important;
    }
  }
  padding-left: 100px !important;
  padding-right: 100px !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

// .mobile-nav {
//   @media (max-width: 1024px) {
//     z-index: 0;
//   }
// }

.blue-pattern {
  background-image: url("/web/patterns/blue-pattern.png") !important;
}

.bgClip {
  padding-top: 25px;
  background-image: url("/images/text-pattern.png");
  background-repeat: no-repeat;
  background-size: 480px;
  @media (max-width: 1024px) {
    -webkit-text-stroke: 3px #db0366;
  }

  @media (max-width: 800px) {
    letter-spacing: 3px !important;
    -webkit-text-stroke: 2px #db0366;
    background-size: 170px;
  }
  -webkit-text-stroke: 4px #db0366; /* width and color */
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-button:hover {
  .main-button-more {
    filter: brightness(100%);
    display: grid;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
  }
  .main-button-sticker {
    filter: brightness(100%);
    margin-top: 100px !important;
    @media (max-width: 900px) {
      margin-top: 20px !important;
    }
  }
  .darken-background {
    filter: brightness(50%);
  }
}
.main-button {
  .main-button-more {
    display: none;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
  }
}

.ticket-btn {
  background-color: #db0366;
  background-size: cover;
  padding: 30px 30px;
  margin-top: 50px;
  display: inline-block;
  border-radius: 30px;
  @media (max-width: 800px) {
    padding: 24px;
    font-size: 14px;
  }
}
.description {
  p {
    margin-bottom: 20px;
  }
}
